import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41b0fcfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.getHref(),
    class: "phone-link",
    title: _ctx.href
  }, _toDisplayString(_ctx.text), 9, _hoisted_1))
}