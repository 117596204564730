
import { defineComponent } from "vue";

export default defineComponent({
    name: "ActionLink",
    props: {
        href: { type: String, required: true },
        text: { type: String, required: true },
        type: { type: String, default: 'phone' },
    },

    methods: {
        getHref() {
            let hrefType = this.type === 'phone' ? 'tel' : 'mailto';
            return `${hrefType}: ${this.href}`;
        }

    }
});
