
import { defineComponent } from "vue";

export default defineComponent({
    name: "DownloadBtn",
    props: {
        url: { type: String, required: true },
        fileName: { type: String },
    },
    methods: {
        itemClicked() {
            const link = document.createElement('a');
            link.href = this.url;
            (link as any).filename = this.fileName ?? this.url;
            link.target = "_blank";
            document.body.appendChild(link)
            link.click()
        }
    }
});
