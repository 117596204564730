
import { defineComponent } from "vue";
import DownloadBtn from "./DownloadBtn.vue";

export default defineComponent({
    components: { DownloadBtn },
    name: "CompanyCard",
    data() {
        return {
            items: new Map<string, string>(),
            requisites: new Map<string, string>()
        }
    },

    created() {
        this.items.set('Полное наименование предприятия', 'Общество с ограниченной ответственностью «Точка Учета»');
        this.items.set('Сокращенное наименование юридического лица', 'ООО «Точка Учета»');
        this.items.set('Предприятие действует на основании', 'Устава');
        this.items.set('Юридический адрес', '660036, г. Красноярск, ул. Академгородок  23, 30');
        this.items.set('Почтовый адрес', '660036, г. Красноярск, ул. Академгородок  23, 30');
        this.items.set('Телефон', '+7 913 529-71-05, +7 913 532-25-63, +7 391 215-02-70');
        this.items.set('Электронная почта', 'shvv1999@mail.ru mretk@mail.ru provkrsk@yandex.ru;');
        this.items.set('ИНН/КПП', '2463112192/246301001');
        this.items.set('ОГРН', '1182468002122');
        this.items.set('ОКПО', '22667578');
        this.items.set('ОКАТО', '04401371000');
        this.items.set('ОКТМО', '04701000001');
        this.items.set('ОКВЭД', '62.01, 33.14, 46.46, 46.51, 47.41, 72.19');
        this.items.set('Генеральный Директор', 'Шушпанов Вадим Вадимович');
        this.items.set('Сайт', 'https://counting-point.ru/');

        this.requisites.set('Банк', 'Красноярское отделение N 8646 ПАО Сбербанк, г. Красноярск');
        this.requisites.set('Кор.счет', '30101810800000000627');
        this.requisites.set('БИК', '040407627');
        this.requisites.set('Расчетный счет', '40702810931000014906');
    },

    methods: {

        getItems() {
            return Array.from(this.items.entries());
        },

        getRequisites() {
            return Array.from(this.requisites.entries());
        },

        getTitle(item: any) {
            return `${item[0]}: ${item[1]}`;
        }
    }
});
