
import { defineComponent, onMounted, ref } from "vue";
import PageHeader from "./PageHeader.vue";
import AboutCompany from "./AboutCompany.vue";
import TechStackBlock from "./TechStackBlock.vue";
import DocsBlock from "./DocsBlock.vue";
import FooterBlock from "./FooterBlock.vue";


export default defineComponent({
  components: { PageHeader, AboutCompany, TechStackBlock, DocsBlock, FooterBlock },
  name: "MainPage",
  setup() {
    const content = ref<HTMLDivElement>();
    const about = ref<InstanceType<typeof AboutCompany>>();
    const techStack = ref<InstanceType<typeof AboutCompany>>();
    const docs = ref<InstanceType<typeof AboutCompany>>();
    const contacts = ref<InstanceType<typeof AboutCompany>>();

    const goToEl = (id: string | number) => {
      // debugger; // eslint-disable-line no-debugger

      if (id === 'about') {
        about.value?.$el.scrollIntoView();
      }

      if (id === 'techStack') {
        techStack.value?.$el.scrollIntoView();
      }

      if (id === 'docs') {
        docs.value?.$el.scrollIntoView();
      }

      if (id === 'contacts') {
        contacts.value?.$el.scrollIntoView();
      }

    }

    return {
      content,
      about,
      techStack,
      docs,
      contacts,
      goToEl
    }
  },
  data() {
    return {
      isHidden: true,
    };
  },
  created() {
    setTimeout(() => {
      this.isHidden = false;
    }, 800);
  },

  methods: {
    navItemClicked(item: { id: string | number; }) {
      this.goToEl(item.id);
    }
  }
});
